import {SPECS} from '../../../domain/specs';
import {ReadOnlyExperiments} from '@wix/wix-experiments';
import {VeloInputs} from '../../../types/app.types';

export function wixcodePublicApi(experiments: ReadOnlyExperiments, setVeloInputs: (x: VeloInputs) => Promise<void>) {
  return {
    showSecureCheckout: async () => {
      if (experiments.enabled(SPECS.SecureCheckoutVelo)) {
        await setVeloInputs({shouldShowSecureCheckout: true});
      }
    },
    hideSecureCheckout: async () => {
      if (experiments.enabled(SPECS.SecureCheckoutVelo)) {
        await setVeloInputs({shouldShowSecureCheckout: false});
      }
    },
  };
}
