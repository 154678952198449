import {NavigationService} from '../services/NavigationService';
import {StyleSettingsService} from '../services/StyleSettingsService';
import {CheckoutNavigationService, SiteStore, ModalType} from '@wix/wixstores-client-storefront-sdk';
import {CartService} from '../services/CartService';
import {StoreMetaDataService} from '../services/StoreMetaDataService';
import {ORIGIN, OriginTypes, ShippingMethodType} from '../../components/cart/constants';
import {clickOnCheckoutSfParams, clickOnCheckoutWithEWalletSfParams} from '@wix/bi-logger-ec-sf/v2/types';
import {OrderService} from '../services/OrderService';
import {MinimumOrderAmountService} from '../services/MinimumOrderAmountService';

export class NavigationStore {
  private readonly navigationService: NavigationService;
  private readonly styleSettingsService: StyleSettingsService;
  private readonly checkoutNavigationService: CheckoutNavigationService;
  private readonly cartService: CartService;
  private readonly orderService: OrderService;
  private readonly storeMetaDataService: StoreMetaDataService;
  private readonly minimumOrderAmountService: MinimumOrderAmountService;

  constructor(
    private readonly siteStore: SiteStore,
    {
      navigationService,
      styleSettingsService,
      checkoutNavigationService,
      cartService,
      storeMetaDataService,
      orderService,
      minimumOrderAmountService,
    }: {
      navigationService: NavigationService;
      styleSettingsService: StyleSettingsService;
      checkoutNavigationService: CheckoutNavigationService;
      cartService: CartService;
      storeMetaDataService: StoreMetaDataService;
      orderService: OrderService;
      minimumOrderAmountService: MinimumOrderAmountService;
    }
  ) {
    this.navigationService = navigationService;
    this.styleSettingsService = styleSettingsService;
    this.cartService = cartService;
    this.orderService = orderService;
    this.checkoutNavigationService = checkoutNavigationService;
    this.storeMetaDataService = storeMetaDataService;
    this.minimumOrderAmountService = minimumOrderAmountService;
  }

  private readonly navigateToCheckoutInternal = async ({
    accessibilityEnabled,
  }: {
    accessibilityEnabled: boolean;
  }): Promise<void> => {
    this.cartService.trackInitiateCheckout();

    const {hasCreatedPaymentMethods, activePaymentMethods, canStoreShip, isPremium} =
      await this.storeMetaDataService.get();

    const isPickupFlow = this.orderService.isPickup;

    const {canCheckout, modalType} = this.checkoutNavigationService.checkIsAllowedToCheckout({
      areAllItemsDigital: this.cartService.isDigitalCart,
      isPremium,
      canStoreShip,
      hasCreatedPaymentMethods,
      canShipToDestination: this.orderService.canShip || this.cartService.isFullAddressRequired,
    });

    const options = this.cartService.cart.shippingRuleInfo?.shippingRule?.options || [];

    const selectedShippingRuleOption = options.find(
      (option) => option.id === this.cartService.cart.selectedShippingOption.id
    );

    const existingShippingMethodType = isPickupFlow ? ShippingMethodType.PICKUP : ShippingMethodType.SHIPPING;
    const shippingMethodType = this.cartService.isDigitalCart ? ShippingMethodType.NONE : existingShippingMethodType;

    const BIData: clickOnCheckoutWithEWalletSfParams & clickOnCheckoutSfParams = {
      origin: ORIGIN,
      cartId: this.cartService.cart.cartId,
      itemsCount: this.cartService.itemsCount,
      cartType: this.cartService.cartType,
      productsList: JSON.stringify(
        this.cartService.cart.items.map((item) => ({id: item.product.id, quantity: item.quantity}))
      ),
      num_of_paymet_providers: activePaymentMethods.length,
      is_with_ewallet_payment: await this.storeMetaDataService.hasEWalletPaymentMethods(),
      is_with_offline_payment: await this.storeMetaDataService.hasOfflinePaymentMethods(),
      paymet_providers: (await this.storeMetaDataService.getPaymentMethodsNames()).join(','),
      orig_shipping_method: selectedShippingRuleOption?.title,
      shippingMethodType,
    };

    if (!canCheckout) {
      const response = await this.checkoutNavigationService.openModalByType(
        modalType,
        this.styleSettingsService.isEditorX,
        this.cartService.cart
      );

      const shouldProceedToCheckout = modalType === ModalType.UpgradeToPremium && response?.proceed;
      if (!shouldProceedToCheckout) {
        return;
      }
    }

    const deviceType = this.siteStore.isDesktop() ? 'desktop' : 'mobile';

    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.siteStore.biLogger.clickOnCheckoutSf(BIData);

    return this.checkoutNavigationService.navigateToCheckout({
      a11y: accessibilityEnabled,
      cartId: this.cartService.cart.cartId,
      locale: this.siteStore.locale,
      deviceType,
      originType: OriginTypes.AddToCart,
      isPickupOnly: isPickupFlow,
      siteBaseUrl: this.siteStore.location.baseUrl,
      ...(this.cartService.checkoutId ? {checkoutId: this.cartService.checkoutId} : {}),
    });
  };

  private readonly navigateToCheckout = async ({accessibilityEnabled}: {accessibilityEnabled: boolean}) => {
    this.navigationService.isNavigationToCheckoutInProcess = true;
    await this.cartService.createCheckout();
    await this.navigateToCheckoutInternal({accessibilityEnabled});
    this.navigationService.isNavigationToCheckoutInProcess = false;
  };

  private get isCheckoutButtonDisabled() {
    return (
      this.navigationService.isNavigationToCheckoutInProcess ||
      !this.cartService.areAllItemsInStock ||
      this.minimumOrderAmountService.shouldDisableCheckout
    );
  }

  public async toProps() {
    return {
      isCheckoutButtonDisabled: this.isCheckoutButtonDisabled,
      locale: this.siteStore.locale,
      continueShopping: this.navigationService.continueShopping,
      continueShoppingHref: await this.navigationService.getContinueShoppingHref(),
      navigateToProduct: this.navigationService.navigateToProduct,
      shouldRenderContinueShopping: this.styleSettingsService.shouldRenderContinueShopping,
      navigateToCheckout: this.navigateToCheckout,
    };
  }
}
